















































































import {
  defineComponent, useRouter, ref, watch,
} from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { IBlokSingleImage } from '~/components/storyblok/SingleImage.vue';
import SfCarousel from '~/components/StorefrontUi/SfCarousel.vue';
import { resolveUrl } from '~/modules/storyblok/helpers/resolveUrl';

interface ISlide {
  _uid: string;
  banner: IBlokSingleImage[];
  link: {
    url: string;
  };
}

interface IBlok {
  _uid: string;
  slide: ISlide[];
}

export default defineComponent({
  name: 'SliderBlok',
  components: {
    SfCarousel,
  },
  props: {
    blok: {
      type: Object as PropType<IBlok>,
      required: true,
    },
  },
  setup({ blok }: { blok: IBlok }) {
    const router = useRouter();

    const pushToUrl = (link) => {
      const isStoryLink = link.linktype === 'story';
      const url = resolveUrl(link.cached_url as string);
      if (!isStoryLink) {
        window.open(url, '_blank');
      } else {
        const isHomePageUrl = url === 'home';
        const pushUrl = isHomePageUrl ? '/' : `/${decodeURIComponent(url)}`;
        router.push(pushUrl);
      }
    };

    const { slide } = blok;
    const isSingleSlide = slide.length === 1 && slide[0].banner.length === 1;

    const activeIndex = ref(0);
    const carouselRef = ref(null);
    const goToSpecificSlide = (slideId: number) => {
      carouselRef.value.goToSlide(slideId + 1);
    };

    watch(
      // eslint-disable-next-line consistent-return
      () => {
        if (carouselRef.value && carouselRef.value.glide) {
          return carouselRef.value.glide.index;
        }
      },
      (newVal, oldVal) => {
        if (newVal !== oldVal) {
          activeIndex.value = newVal;
        }
      },
    );

    return {
      slide,
      pushToUrl,
      isSingleSlide,
      carouselRef,
      goToSpecificSlide,
      activeIndex,
    };
  },
});
