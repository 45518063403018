//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import SfCarouselItem from '@storefront-ui/vue/src/components/organisms/SfCarousel/_internal/SfCarouselItem.vue';
import Glide from '@glidejs/glide';
import { SfImage } from '@storefront-ui/vue';
import { addBasePath } from '~/helpers/addBasePath';

Vue.component('SfCarouselItem', SfCarouselItem);
export default {
  name: 'SfCarousel',
  components: {
    SfImage,
  },
  props: {
    arrows: {
      type: Boolean,
      default: false,
    },
    dots: {
      type: Boolean,
      default: true,
    },
    mobileDotsPcArrows: {
      type: Boolean,
      default: false,
      required: false,
    },
    settings: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      glide: null,
      defaultSettings: {
        type: 'carousel',
        rewind: true,
        perView: 1,
        slidePerPage: true,
        gap: 0,
        peek: {
          before: 0,
          after: 0,
        },
        breakpoints: {
          1023: {
            perView: 1,
            peek: {
              before: 0,
              after: 0,
            },
          },
        },
      },
    };
  },
  computed: {
    mergedOptions() {
      let breakpoints = { ...this.defaultSettings.breakpoints };
      if (this.settings.breakpoints) {
        breakpoints = { ...breakpoints, ...this.settings.breakpoints };
      }
      return {
        ...this.defaultSettings,
        ...this.settings,
        breakpoints,
      };
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.$slots.default) return;
      const glide = new Glide(this.$refs.glide, this.mergedOptions);
      const size = this.$slots.default.filter((slot) => slot.tag).length;
      glide.size = size;
      if ((size <= glide.settings.perView) && this.$refs.controls) {
        glide.settings.perView = size;
        glide.settings.rewind = false;
        this.$refs.controls.style.display = 'none';
      }

      glide.mount();

      glide.on('run.before', (move) => {
        const { slidePerPage, rewind, type } = this.mergedOptions;
        if (!slidePerPage) return;
        const { perView } = glide.settings;
        if (!perView > 1) return;
        const { direction } = move;
        let page; let
          newIndex;
        switch (direction) {
          case '>':
          case '<':
            page = Math.ceil(glide.index / perView);
            newIndex = page * perView + (direction === '>' ? perView : -perView);
            if (newIndex >= size) {
              newIndex = type === 'slider' && !rewind ? glide.index : 0;
            } else if (newIndex < 0 || newIndex + perView > size) {
              newIndex = type === 'slider' && !rewind ? glide.index : size - perView;
            }
            move.direction = '=';
            move.steps = newIndex;
            break;
          default:
            break;
        }
      });
      this.glide = glide;
    });
  },
  beforeDestroy() {
    if (this.glide) {
      this.glide.destroy();
    }
  },
  methods: {
    addBasePath,
    go(direct) {
      if (!this.glide) return;
      switch (direct) {
        case 'prev':
          this.glide.go('<');
          break;
        case 'next':
          this.glide.go('>');
          break;
        default:
          break;
      }
    },
    goToSlide(index) {
      if (this.glide) {
        const actualIndex = index - 1;
        this.glide.go(`=${actualIndex}`);
      }
    },
    isActiveDot(glide, i) {
      return glide.settings.perView !== 1
        ? (i * glide.settings.perView - glide.settings.perView - glide.index === 0
          ? i * glide.settings.perView - glide.settings.perView === glide.index
          : i * glide.settings.perView - glide.settings.perView === glide.index + 1)
        : i * glide.settings.perView - glide.settings.perView === glide.index;
    },
  },
};
