var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isSingleSlide)?_c('div',{directives:[{name:"editable",rawName:"v-editable",value:(_vm.blok),expression:"blok"}]},[_c('div',{staticClass:"w-full cursor-pointer lg:hidden flex justify-center items-center",on:{"click":function($event){return _vm.pushToUrl(_vm.slide[0].link)}}},[_c('StoryblokComponent',{attrs:{"blok":_vm.slide[0].banner[0]}})],1),_vm._v(" "),_c('div',{staticClass:"w-full cursor-pointer hidden lg:block",on:{"click":function($event){return _vm.pushToUrl(_vm.slide[0].link)}}},[_c('StoryblokComponent',{attrs:{"blok":_vm.slide[0].banner[0]}})],1)]):_c('div',{staticClass:"relative"},[_c('SfCarousel',{ref:"carouselRef",staticClass:"w-full",attrs:{"arrows":true,"dots":false,"mobile-dots-pc-arrows":true,"settings":Object.assign({}, {type: 'slider',
      rewind: true,
      perView: 1,
      slidePerPage: true,
      gap: 10},
      (_vm.blok.swiperAutoplay && { autoplay: 2000 }))}},_vm._l((_vm.blok.slide),function(childrenBlok){return _c('SfCarouselItem',{key:childrenBlok._uid + 'slide'},[_c('div',{staticClass:"block w-full bg-cover bg-center cursor-pointer",on:{"click":function($event){return _vm.pushToUrl(childrenBlok.link)}}},_vm._l((childrenBlok.banner),function(childrenBlokBanner){return _c('div',{key:childrenBlokBanner._uid + 'sliderBlok-banner',staticClass:"w-full block"},[_c('StoryblokComponent',{attrs:{"blok":childrenBlokBanner}})],1)}),0)])}),1),_vm._v(" "),_c('div',{staticClass:"w-full lg:flex bg-white rounded-full max-w-fit justify-center mx-auto left-0 right-0 absolute nav-item-wrapper hidden"},_vm._l((_vm.blok.slide),function(childrenBlok,index){return _c('div',{key:childrenBlok._uid + 'nav'},_vm._l((childrenBlok.banner),function(childrenBlokBanner){return _c('button',{key:childrenBlokBanner._uid + 'nav-item',staticClass:"text-sm text-center",class:[
          'cursor-pointer border px-3 py-1 rounded-full mr-1 duration-300',
          index === _vm.activeIndex ? 'bg-primary border-primary text-white' : 'border-white bg-white text-black' ],attrs:{"type":"button"},on:{"click":function($event){return _vm.goToSpecificSlide(index)}}},[_vm._v("\n        "+_vm._s(childrenBlokBanner.Alt || childrenBlokBanner.title)+"\n      ")])}),0)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }